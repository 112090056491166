
import React ,{useState} from 'react'
import { freelancerIndiviualValidationSchema } from '../../../blocks/social-media-account-registration/src/validationSchema';
import { Formik, Field, Form, FieldArray } from "formik";
import Select from 'react-select'
import InputError from '../../../blocks/social-media-account-registration/src/InputError.web';
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../blocks/social-media-account-registration//assets/css/Accountinfo.css";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {AddOutlined} from '@material-ui/icons'
import {ICategoryDataModel, IEducationalQualification, IFieldOfStudy, IUniversityCollege } from '../../../blocks/social-media-account-registration/src/types';
import FormSaver from './FormSaver.web';
import LocationContainer from './LocationContainer';
import { createTheme} from "@material-ui/core/styles";
import CustomSelect from '../utill/CustomSelect.web';
import {MenuItem,IconButton,Box} from '@material-ui/core'

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          fontSize: '14px', 
          fontWeight:700,
          color:"#8083A3"
        },
      },
    },
  },
});

interface Props{
    initialValues?:any;
    submitHandler:any;
    cancelHandler:any;
    uploaded_documents:any;
    handleDocumentChange:any;
    submitLoading:boolean;
    countryList:string[];
    countryLoading:boolean;
    cityList:string[];
    cityLoading:boolean;
    getCityList:any;
    skills:ICategoryDataModel[];
    categories:ICategoryDataModel[];
    getEducationLoading:boolean;
    educationList:IEducationalQualification[];
    fieldOfStudyList:IFieldOfStudy[];
    universityList:IUniversityCollege[];
    stateList:string[];
    getStateLoading:boolean;
    getStateList:any;
    freelancingExperienceList:string[];
    handleOpenUniversityModal:(open:boolean)=>void;
}
const init={
    skill_ids: [],
    hobby_ids: [],
    uploaded_documents: null,
    certification: "",
    experience_in_years: "",
    education: "",
    college: "",
    field: "",
    jobTitle: "",
    employerName: "",
    country: "",
    city: "",
    jobDescription: "",
    currently_working_here: false,
    educational_qualifications: [
      { education: "", college: "", field: "" }
    ],
    career_experiences: [
      {
        jobTitle: "",
        employerName: "",
        country: "",
        city: "",
        jobDescription: ""
      }
    ]
  }



const getInitialValue=()=>{
  const individualString= localStorage.getItem("individualForm")
  let initialValue=init;
  try{
    if(individualString){
      const parsedValue=JSON.parse(individualString);
      initialValue=parsedValue;
    }
    return initialValue;
  }catch(err){
     return initialValue;
  }
}



const IndividualForm:React.FC<Props>=({
    initialValues=getInitialValue(),
    submitHandler,
    uploaded_documents,
    handleDocumentChange,
    cancelHandler,
    submitLoading,
    cityList,
    cityLoading,
    countryList,
    countryLoading,
    getCityList,
    categories,
    skills,
    educationList=[],
    universityList,
    fieldOfStudyList,
    getStateList,
    getStateLoading,
    stateList,
    handleOpenUniversityModal
})=>{
  const [updateInfo,setUpdateInfo]=useState(true);

  const handleGoBack=()=>{
    setUpdateInfo(false)
    cancelHandler();
  }


    return(
     <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={freelancerIndiviualValidationSchema}
        onSubmit={submitHandler}
      >
      {(props)=>(
        <FormSaver {...props} saveFieldName={"individualForm"} saveInfo={updateInfo}>
        {({ values, setFieldValue,errors }:any) => {
          return(
            <Form translate={undefined}>
              <div className="step-1">
                <div className="form-group fakeclass form-linebreak multiselect">
                  <label htmlFor="skill">Skills*</label>
                  <Select test-id="skillId"
                    id="skill_ids"
                    className="multiselect-width"
                    isClearable={false}
                    name="skill_ids"
                    placeholder="Search and select your skills"
                    options={skills}
                    isMulti
                    value={values.skill_ids}
                    onChange={(selectedValues)=>{
                      setFieldValue("skill_ids",selectedValues?selectedValues:[])
                    }}
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontWeight:700, 
                        color:"#8083A3",
                      }),
                    }}
                  />
                  <InputError
                    errors={errors}
                    name={"skill_ids"}
                  />
                </div>
                <div className="form-group form-linebreak multiselect" data-test-id="fakeid">
                  <label htmlFor="hobby_ids">
                    Categories Interested*
                  </label>
                  <Select
                    className="multiselect-width"
                    test-id="categoriesId"
                    id="hobby_ids"
                    name="hobby_ids"
                    isClearable={false}
                    placeholder="Search and select your Categories"
                    options={categories}
                    isMulti
                    value={values.hobby_ids}
                    onChange={(selectedHobby)=>{
                      setFieldValue("hobby_ids",selectedHobby?selectedHobby:[])
                    }}
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontWeight:700, 
                        color:"#8083A3",
                      }),
                    }}
                  />
                  <InputError
                    errors={errors}
                    name="hobby_ids"
                  />
                </div>
                <div className="form-group form-linebreak" id="faketestid">
                  <label htmlFor="upload">Upload Resume</label>
                  {uploaded_documents ? uploaded_documents.name : <span>
                    Upload your resume (.DOC, .DOCX, .PDF. Max file
                    size 10MB)
                  </span>}
                  <div className="upload-btn">
                    <div className="upload-btn-wrapper">
                      <button
                        type="button"
                        className="button btn-orange"
                      >
                        Upload a file
                      </button>
                      <input test-id="uploadId"
                        type="file"
                        id="uploaded_documents"
                        name="uploaded_documents"
                        accept=".pdf, .doc, .docx"
                        placeholder="Upload your resume (.DOC, .DOCX, .PDF. Max file size 10MB)"
                        onChange={(event: any) => {
                          setFieldValue(
                            "uploaded_documents",
                            event.currentTarget.files[0]
                          );
                          handleDocumentChange(event)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="certification" data-test-id="checkkerror">
                    Certifications
                  </label>
                  <Field test-id="certificate"
                    type="text"
                    id="certification"
                    name="certification"
                    placeholder="Enter your certifications"
                  />
                  <InputError
                    errors={errors}
                    name="certification"
                  />
                </div>
                  <div className="form-group">
                    <label htmlFor="freelanceexperience">Freelancing Experience</label>
                    <input
                        type="number"
                        value={values?.experience_in_year}
                        onChange={(e:any)=>setFieldValue("experience_in_years",e.target.value)}
                        data-testid="experience"
                        name="experience_in_years"
                        tabIndex={3}
                        placeholder="Select Years"
                        min="0"
                      />
                      <InputError
                      errors={errors}
                      name="experience_in_years"
                    />
                  </div>
              </div>
              <div className="step-2">
                <div className="block-title">
                  <h6>Education Details</h6>
                </div>
                <div>
                  <FieldArray name="educational_qualifications" test-id="educationDetails">
                    {({ push,remove }) => {
                      const educationErrors=errors?.educational_qualifications || [] as any;
                      return(
                        <div>
                          {values.educational_qualifications.map(
                            (detail:any, index:number) => (
                              <div
                                className="block-content"
                                key={index}
                              >
                                <div className="block-inner">
                                  <p>Education Details {index + 1}</p>
                                  {values.educational_qualifications.length > 1 && (
                                      <span
                                        test-id="removeExperienceDetailsId"
                                        className="link"
                                        onClick={() => remove(index)}
                                      >
                                        Remove
                                      </span>
                                    )}
                                  {index ===
                                    values.educational_qualifications.length -
                                    1 && (
                                      <span test-id="addEducationId"
                                        className="link"
                                        onClick={() =>
                                          push({
                                            education: "",
                                            college: "",
                                            field: ""
                                          })
                                        }
                                      >
                                        + Add another education detail
                                      </span>
                                    )}
                                   
                                </div>
                                <div className="d-flex" test-id="educationFormId">
                                  <CustomSelect
                                    value={values?.educational_qualifications[index]?.education}
                                    onChange={(e:any)=>setFieldValue(`educational_qualifications.${index}.education`,e.target.value)}
                                    label="Education"
                                    renderItem={(item,props)=>(
                                        <MenuItem
                                          key={item?.id}
                                          value={item?.id}
                                          {...props}
                                        >
                                        {item?.attributes?.education}
                                      </MenuItem>
                                    )}
                                    name={`educational_qualifications.${index}.education`}
                                    id={`educational_qualifications.${index}.education`}
                                    data={educationList}
                                    error={educationErrors[index]?.education || ""}
                                    placeholder=" Select your degree/diploma"
                                    labelStyle={{
                                      marginBottom:"14px"
                                    }}
                                    // rootStyle={{
                                    //   paddingRight:"20px"
                                    // }}
                                  />
                                  <CustomSelect
                                    label=" University/College"
                                    value={values?.educational_qualifications[index]?.college}
                                    onChange={(e:any)=>setFieldValue(`educational_qualifications.${index}.college`,e.target.value)}
                                    placeholder=" Select your University/College"
                                    name={`educational_qualifications.${index}.college`}
                                    id={`educational_qualifications.${index}.college`}
                                    data={universityList}
                                    error={educationErrors[index]?.college || ""}
                                    renderItem={(item,props)=>(
                                      <MenuItem
                                        key={item?.id}
                                        value={item?.id}
                                        {...props}
                                      >
                                      {item?.attributes?.name}
                                    </MenuItem>
                                  )}
                                    labelStyle={{
                                      marginBottom:"14px"
                                    }}
                                    labelSuffix={
                                      <IconButton style={{padding:0,marginLeft:"12px"}} onClick={()=>handleOpenUniversityModal(true)}>
                                        <AddOutlined/>
                                      </IconButton>
                                    }
                                  />
                                </div>

                                  <CustomSelect
                                    label="Field of Study"
                                    value={values?.educational_qualifications[index]?.field}
                                    onChange={(e:any)=>setFieldValue(`educational_qualifications.${index}.field`,e.target.value)}
                                    placeholder="Select your field of study"
                                    name={`educational_qualifications.${index}.field`}
                                    id={`educational_qualifications.${index}.field`}
                                    data={fieldOfStudyList}
                                    error={educationErrors[index]?.field || ""}
                                    renderItem={(item,props)=>(
                                      <MenuItem
                                        key={item?.id}
                                        value={item?.id}
                                        {...props}
                                      >
                                      {item?.attributes?.field_of_study}
                                    </MenuItem>
                                  )}
                                    labelStyle={{
                                      marginBottom:"14px"
                                    }}
                                  />
                                  { values.educational_qualifications.length-1 === index &&
                                          <div style={{marginTop:"-24px"}} className='d-flex'>
                                        <FormControlLabel
                                            control={
                                              <Checkbox
                                                  checked={values?.educational_qualifications[index]?.current_study}
                                                  onChange={(e:any)=> setFieldValue(`educational_qualifications.${index}.current_study`, e.target.checked)}
                                                  name="checkedB"
                                                  color="primary"
                                                />
                                            }
                                            label="I currently study here"
                                            />
                                        </div>}
                              </div>
                            )
                          )}
                        </div>
                      )
                    }}
                  </FieldArray>
                </div>
              </div>
              {
                      values?.experience_in_years > 0 &&
                      (

                        <div className="step-3">
                          <div className="block-title">
                            <h6>Experience Details</h6>
                          </div>
                          <div>
                            <FieldArray name="career_experiences" test-id="experienceDetailsId">
                              {({ push,remove }) => {
                                  const experienceErrors=errors?.career_experiences || [] as any;
                                return(
                                  <div>
                                    {values.career_experiences.map(
                                      (detail:any, index:number) => (
                                        <div
                                          className="block-content"
                                          key={index}
                                        >
                                          <div className="block-inner">
                                            <p>
                                              Experience Details {index + 1}
                                            </p>
                                            {values.career_experiences.length > 1 && (
                                                <span
                                                  test-id="removeExperienceDetailsId"
                                                  className="link"
                                                  onClick={() => remove(index)}
                                                >
                                                  Remove
                                                </span>
                                              )}

                                          </div>
                                          <div className="d-flex solveclass" test-id="experienceFormId">
                                            <div className="form-group form-linebreak" id="checkid">
                                              <label
                                              className="labelclass"
                                                htmlFor={`career_experiences.${index}.jobTitle`}
                                              >
                                                Job Title*
                                              </label>
                                              <Field
                                                name={`career_experiences.${index}.jobTitle`}
                                                id={`career_experiences.${index}.jobTitle`}
                                                placeholder="Job Title"
                                              />
                                              <InputError errors={experienceErrors[index] || {}} name="jobTitle"/>
                                            </div>
                                            <div className="form-group checkingcl">
                                              <label
                                                data-test-id="hellotest"
                                                htmlFor={`career_experiences.${index}.employerName`}
                                              >
                                                Employer Name*
                                              </label>
                                              <Field
                                                name={`career_experiences.${index}.employerName`}
                                                id={`career_experiences.${index}.employerName`}
                                                placeholder="Employer Name"
                                              />
                                              <InputError errors={experienceErrors[index] || {}} name="employerName"/>
                                            </div>
                                          </div>
                                        <LocationContainer
                                            key={index}
                                            cityLoading={cityLoading}
                                            index={index}
                                            countryLoading={countryLoading}
                                            experienceErrors={experienceErrors}
                                            getCityList={getCityList}
                                            getStateList={getStateList}
                                            getStateLoading={getStateLoading}
                                            setFieldValue={setFieldValue}
                                            values={values}
                                            countryList={countryList}
                                            cityList={cityList}
                                            stateList={stateList}
                                        />
                                          <div className="form-linebreak form-group">
                                            <label
                                              htmlFor={`career_experiences.${index}.jobDescription`}
                                            >
                                              Job Description*
                                            </label>
                                            <Field
                                              name={`career_experiences.${index}.jobDescription`}
                                              id={`career_experiences.${index}.jobDescription`}
                                              as="textarea"
                                              rows="5"
                                              placeholder="Enter job description"
                                            />
                                              <InputError errors={experienceErrors[index] || {}} name="jobDescription"/>
                                          </div>
                                          
                                            <div>
                                              {index ===
                                                values.career_experiences.length -
                                                1 && (
                                                  <span test-id="addExperienceDetailsId"
                                                    className="link"
                                                    onClick={() =>
                                                      push({
                                                        jobTitle: "",
                                                        employerName: "",
                                                        country: "",
                                                        city: "",
                                                        state: "",
                                                        jobDescription: "",
                                                        currently_working_here: false
                                                      })
                                                    }
                                                  >
                                                    + Add another experience detail
                                                  </span>
                                                )}
                                            </div>
                                            {values.career_experiences.length-1 === index &&
                                          <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={values?.career_experiences[index]?.currently_working_here}
                                                  onChange={(e:any)=> {
                                                    if(e.target.checked){
                                                      values?.career_experiences?.forEach((item:any,idx:number)=>{
                                                            if(idx===index){
                                                              setFieldValue(`career_experiences.${index}.currently_working_here`, e.target.checked)
                                                            }else{
                                                              setFieldValue(`career_experiences.${idx}.currently_working_here`,false)
                                                            }
                                                        })
                                                    }else{
                                                      setFieldValue(`career_experiences.${index}.currently_working_here`, e.target.checked)
                                                    }
                                                    
                                                  }}
                                                  name="checkedB"
                                                  color="primary"
                                                />
                                              }
                                              label=" I currently work here"
                                            />}
                                        </div>
                                      )
                                    )}
                                  </div>
                                )
                              }}
                            </FieldArray>
                          </div>
                        </div>
                      )
                      }
              <Box className="button-block" display="flex" flexWrap="wrap">
                <button type="reset" className="button btn" onClick={handleGoBack}>
                  Cancel
                </button>
                <button disabled={submitLoading} type="submit" className="button btn-orange" test-id="submitButtonId">
                {
                  submitLoading?<CircularProgress size={16} />:"Complete"
                }
              </button>
              </Box>
            </Form>
          )
        }}
        </FormSaver>
      )}
      </Formik>
    )
}


export default IndividualForm;