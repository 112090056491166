// Customizable Area Start
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import CreateRestApiMessage from "../../../../components/src/CreateRestApiMessage.web";
import { IProjectData } from "../types";
export const configJSON = require("../config");
export const PROJECT_PER_PAGE = 5;
import {toast} from 'react-toastify';
export interface Props {
  navigation: any;
  id: string;
  param: "browse-all" | "completed" | "progress" | "my-bid"  |"dispute" | "onhold" | "closed";
}

interface S {
  projectList: IProjectData[];
  projectLoading: boolean;
  totalProjects: number;
  currentPage: number;
  activeTab: number;
  searchText: string;
  queryString: string;
  isActiveAll: boolean;
  isNegotiationTrue: boolean;
  currentUser: string;
  favouriteProject_id: string;
  favouriteProject_type: string;
  isFavouriteProfileID: number;
  showSimilar:boolean;
  similarProjectId:string;

}

interface SS { }

export default class FreelancerBrowseProjectsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllProjectApiCallId: string = "";
  getSearchProjectsApiCallId: string = "";
  getAllFilteredProjectApiCallId: string = "";
  addFavouritesProjectApiCallId: string = "";
  addFavProjectID: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage),
    ]);

    this.state = {
      projectList: [],
      projectLoading: false,
      totalProjects: 0,
      currentPage: 1,
      activeTab: 0,
      searchText: "",
      queryString: "",
      isActiveAll: true,
      isNegotiationTrue: false,
      currentUser: "",
      favouriteProject_id: "",
      favouriteProject_type: "",
      isFavouriteProfileID: NaN,
      showSimilar:false,
      similarProjectId:""
    };
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
      this.setState({ currentUser: user });
    }
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getAllProjectApiCallId) {
        this.handleGetAllProjectsPayload(responseJson);
      }
      else if (apiRequestCallId === this.addFavouritesProjectApiCallId) {
        this.setState((prev) => {
          const newProjectList = prev.projectList.map((item) => {
            if (item.id === this.addFavProjectID) {
              return { ...item, attributes: { ...item.attributes, is_favourite_project: !item.attributes.is_favourite_project } }

            }
            return item
          })
          return {
            projectList: newProjectList,
            projectLoading:false
          }
        })

      }

    }
  }

  handleGetAllProjectsPayload = (responseJson: any) => {
    if (responseJson && responseJson?.projects?.data) {
      this.setState((prev) => {
        let newProjectList: any[] = [];
        if (prev.currentPage === 1) {
          newProjectList = responseJson?.projects?.data;
        } else {
          newProjectList = [...prev.projectList, ...responseJson.projects.data];
        }
        return {
          projectList: newProjectList,
          projectLoading: false,
          totalProjects: responseJson?.pagination?.total_data || 0,
        };
      });
    } else {
      this.setState({ projectLoading: false,projectList:[],totalProjects:0 });
    }
  };

  async componentDidMount() {
    this.prepareFinalQuery();
    this.sendLoginUserMessage();
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<S>, nextContext: any): boolean {
    if(nextProps.param!==this.props.param){
      this.setState({
         projectList:[],
         projectLoading:true,
      })
      this.prepareFinalQuery(nextProps.param);
      return false;
    }
    return true;
}

  onShowSimilar=(projectId:string)=>{
      this.setState({showSimilar:true,similarProjectId:projectId,projectList:[]},()=>{
         this.prepareFinalQuery();
      })
  }


  sendLoginUserMessage = () => {
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );
    this.send(msg);
  }
  searchHandler = (searchText: string) => {
    this.setState({ currentPage: 1, searchText: searchText,projectList:[] }, () => {
      this.prepareFinalQuery();
    });
  };
  filterHandler = (query: string, selectedOptions: any) => {
    this.setState({ currentPage: 1, queryString: query,projectList:[] }, () => {
      this.prepareFinalQuery();
    });
  };
  cancelFilterHandler = () => {
    this.setState({ currentPage: 1, queryString: "",projectList:[] }, () => {
      this.prepareFinalQuery();
    });
  };
  getTaQueryValue = () => {
    const { activeTab } = this.state;
    if (activeTab === 0) {
      return "";
    } else if (activeTab === 1) {
      return `bid_status=open_bid_project`;
    } else if (activeTab === 2) {
      return `bid_status=non_bid_project`;
    }
  };

  getProjectParamQuery = (query?:string) => {
    const param = query || this.props.param;
    if (param === "browse-all") {
      return "";
    } else if (param === "completed") {
      return `project_status=completed`;
    } else if (param === "progress") {
      return `project_status=in-progress`;
    } else if (param === "my-bid") {
      return ``;
    } else if (param === "closed") {
      return `project_status=closed`;
    } else if (param === "dispute") {
      return `project_status=dispute`;
    }
    else if (param === "onhold") {
      return `project_status=on-hold`;
    } else {
      return "";
    }
  };
  appendURLSearchParams=(queryArr:string[])=>{
    const urlParams = new URLSearchParams(window.location.search);
    const category = urlParams.get('c');
    if(category){
      queryArr.push(
        `project_category[]=${category}`
      );
    }


  }
  prepareFinalQuery = (query?:string) => {
    let finalQuery: string[] = [];
    const { searchText, queryString, currentPage,showSimilar,similarProjectId } = this.state;
    finalQuery.push(`current_page=${currentPage}`);
    finalQuery.push(`per_page=${PROJECT_PER_PAGE}`);

    if(showSimilar){
      finalQuery.push(`show_similar=true`);
      finalQuery.push(`project_id=${similarProjectId}`);
    }
    if (searchText) {
      finalQuery.push(`search=${searchText}`);
    }
    if (queryString) {
      finalQuery.push(queryString);
    }else{
      this.appendURLSearchParams(finalQuery);
    }
    const tabQuery = this.getTaQueryValue();
    if (tabQuery) {
      finalQuery.push(tabQuery);
    }
    const pageQuery = this.getProjectParamQuery(query);
    if (pageQuery) {
      finalQuery.push(pageQuery);
    }
    const newQueryText = finalQuery.join("&");
    this.getAllProjects(newQueryText);
  };

  handleProjectLoadMore = () => {
    const { projectLoading } = this.state;
    if (!projectLoading) {
      this.setState(
        (prev) => ({ currentPage: prev.currentPage + 1 }),
        () => {
          this.prepareFinalQuery();
        }
      );
    }
  };

  getTabValue = () => {
    const { activeTab } = this.state;
    if (activeTab === 0) {
      return "all";
    } else if (activeTab === 1) {
      return "bid-open";
    } else if (activeTab === 2) {
      return "fixed";
    } else if (activeTab === 3) {
      return "non-bid";
    }
  };
  handleTabChange = (e: any, newValue: number) => {
    const { activeTab } = this.state;
    if (activeTab !== newValue) {
      this.setState(
        { activeTab: newValue, currentPage: 1, projectList: [],showSimilar:false,similarProjectId:"" },
        () => {
          this.prepareFinalQuery();
        }
      );
    }
  };
  getAllProjects = (query: string) => {
    this.setState({
      projectLoading: true,
    });
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `/bx_block_profile/projects/get_lancers_projects?${query}`,
      body: null,
      method: "GET",
    });

    this.getAllProjectApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };
  addFavouritesProject = (isFavouriteProfileID: any) => {
    this.addFavProjectID = isFavouriteProfileID
    const token = localStorage.getItem("loginSuccessToken");
    let body = {
      favourite: {
        project_id: isFavouriteProfileID
      }
    }
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFavouritesProjectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_favourites/favourites`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  navigateToBidProjectDetails = (id: string) => {
    this.props?.navigation?.navigate("QuoteProjectDetails", { projectId: id })
  }
  navigateToSubmitBidPage = (id: string) => {
    const project = this.state.projectList.find(data=> data.id === id) || null;

    if(project){
      if(project.attributes.number_of_propsals < project.attributes.number_of_bidders || project.attributes.bid_status === 1) {
        return this.props?.navigation?.navigate("BidProject", { projectId: id })
      }
    }

    return toast.error("Quote limit has been exceeded");
  }
  handleNegotiation = () => {
    this.setState({ isNegotiationTrue: true, isActiveAll: false });
  };
  handleNegotiationFalse = () => {
    this.setState({ isNegotiationTrue: false, isActiveAll: true });
  };
  // Customizable Area Start
  handleProjectDetailNavigation = (id: string) => {
    this.props.navigation.navigate("QuoteDetails", { projectId: id });
  };
  // Customizable Area End
}

// Customizable Area End
