// Customizable Area Start
import React from "react";
import ProjectBidController, {
  Props,
} from "./ProjectBidController.web";
import FreelancerHeader from "../FreelancerHeader.web";
import {Container,Box,Typography,Grid,Button,TextField,Card,Tooltip} from '@material-ui/core'
import ProjectCard from "../../../../components/src/ProjectCard.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {successImg} from '../assets'
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles,createTheme, ThemeProvider } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import { configJSON } from "../SearchBidController.web";
import { calander, info_circle, wallet } from "../../../auctionbidding/src/assets";
export const getCostDisplayElement = (freelancerCost: any, sponsorCost: any, hourlyRated: boolean) => {
  return (
    <>
      {sponsorCost?.currency_symbol} {sponsorCost?.amount} {""}
      {  sponsorCost?.currency != freelancerCost?.currency &&
           <>
              <span datatest-id="milestonedummy" style={{color:"#EC952D" , fontWeight:400, fontSize:"12px"}} >({freelancerCost?.currency_symbol}{freelancerCost?.amount})</span>
              <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} 
                  title={`Converted from (${sponsorCost?.currency}) into (${freelancerCost?.currency}) based on today's conversion rate`}
                  arrow
                  placement="top-start"
                  >
                  <img src={info_circle} alt="info" width={18} height={18} />
              </Tooltip>
          </>
      }
      {hourlyRated && " /hr"}
    </>
  )
} 

const theme = createTheme({
  palette: {},
  overrides: {
    MuiFormLabel: {
      root: {
        lineHeight: 'none',
        '&.Mui-error': {
          color: '#8083a3',
        },
        '&.Mui-focused': {
          color: '#8083a3',
        },
      },
    },

  }
});

const StyledTextField = withStyles({
  root: {
    maxWidth: '100%',
    fontFamily: 'Inter',
    fontSize: '18',
    fontWeight:600,
    color:'#000',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Inter',
      fontSize: '14',
      fontWeight: 400,
      color: '#8083a3'
    },
    "& textarea::placeholder": {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      color: '#8083a3',
    },
    "& label": {
      color: 'rgb(128, 131, 163)',
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#8083A"
  }
  }
})(TextField);

class BidProjectDetails extends ProjectBidController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const {inputs,errors,project,showBidSuccessPage,submitBidLoading,successMessage,currentUser}=this.state;
    const isNonBidProject=project?.attributes?.bid_status===1;
    const isFixedPrice =project?.attributes?.budget_type==="fixed_price";
    const loggedInUserCurrency=currentUser?.attributes?.currency_symbol;
    const milestone = project?.attributes?.project_milestones
    return (
    <ThemeProvider theme={theme}>
         <FreelancerHeader
                    navigation={this.props.navigation}
                    history={undefined}
                    location={undefined}
                    id={""}
                    route={""}
                />
        {showBidSuccessPage? 
        (<Container data-testid="successContainer" style={webStyle.successPageContainer}>
           <img src={successImg} alt="" />
        <Typography component="h1" style={webStyle.headingText}>
          Congratulations
        </Typography>
        <Typography component="h3" style={webStyle.subHeading}>
          {successMessage}
        </Typography>
        <Typography component="p" align="center">
          You will receive a message once the sponsor accepts it.
        </Typography>
        <Box style={{ display: "flex", alignItems: "center", gap: "20px",marginTop:"30px",flexWrap:"wrap", justifyContent: "center" }}>
          <Button
            data-testid="dashboardNavigateBtn"
            style={webStyle.backButton}
            variant="contained"
            onClick={this.navigateToFreelancerDashboard}
          >
            Go to Dashboard
          </Button>
          <Button 
            data-testid="moreProjectNavigateBtn"
            style={webStyle.continueButton} 
            variant="contained"
            onClick={this.navigateToFreelancerBrowseMoreProjects}
            >
            Browse More Projects
          </Button>
        </Box>
      </Container>
      ):
        <Container>
            <br/>
            <br/>
            <Box style={webStyle.header}>
              <Button
                data-testid="backBtn"
                onClick={this.goBack}
                style={webStyle.backButton}
                variant="contained"
                startIcon={<KeyboardBackspaceIcon />}
                >
                Back
              </Button>
              <Typography style={webStyle.headingText}>Quote For Project</Typography>
            </Box>
            <br/>
            {
              project !==null && <ProjectCard card={project} showActionBar={false} fav={undefined} showHoriz={false} />
            }
        
            <br/>
            <style>
              {`
                @media(max-width:767px) {
                  .resBidCardStyle {
                    padding: 20px !important;
                  }
                }
                @media(max-width: 575px) {
                  .MuiGrid-spacing-xs-3 > .MuiGrid-item {
                    padding: 0 !important;
                  }
                }
              `}
            </style>
            <Box style={webStyle.projects_filter_wrapper}>
              <h5>Milestone</h5>
              { this.state.milestoneDetails ?
                <Box>
                {
                  this.state.milestoneDetails?.attributes?.project_milestone?.data?.map((milestone:any)=> (
                    <div key={milestone?.title} style={{
                      background:"#f6f6f9",
                      borderRadius:"15px",
                      padding:"10px",
                      marginBottom:"15px",

                  }}>
                    <Box className="project_common_container">
                                    <span style={{color:"black"}}  className="project_owner_name">{milestone?.attributes?.milestone_title}</span>
                    </Box>
                                      <Box className="project_container1"></Box>
                                        <Box className="project_common_container milestone_container" 
                                        style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                                           {milestone?.attributes?.milestone_description}
                                        </Box>
                    
                                        <Box className="project_container1"></Box>
                                          <Box className="bid_negititation project_common_container">
                                            <Box className="mybid_dashboard_manu1">
                                                <img src={calander} alt="image" width={20} />
                                                <Box className="date_text">{milestone?.attributes?.calculated_due_date}</Box>
                                            </Box>
                                            <Box className="mybid_dashboard_manu1 myBid_verify">
                                                <Box 
                                                    style={{
                                                        display:"flex",
                                                        alignItems:"center",
                                                        gap:"5px",
                                                        margin: 0,
                                                    }}
                                                    className="date_text">
                                                     <img src={wallet} alt="image" width={20} />   
                                                     {getCostDisplayElement(milestone.attributes?.currency_conversion?.freelancer,milestone.attributes?.currency_conversion?.sponsor,false)}
                                                </Box>
                                                <button className="verified-btn">Verified</button>
                                            </Box>
                                        </Box>
                    
                  </div>
                  ))}
                </Box>:
                <Box>
                  {milestone?.map((e)=> 
                   (<Box key={e.id} style={webStyle.milestone_list}>
                      <p style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                      }}>
                        {e?.title}
                      </p>
                      <p>{e?.description}</p>
                    </Box>)
                  )}
                  </Box> 
              }
            </Box>
            
                    
                  
            <Card className="resBidCardStyle" style={webStyle.bidCardStyle} elevation={0}>
            <Grid container spacing={3} className="resSpacingRmv">
            {
              isNonBidProject?null:
              <Grid item xs={12} md={4} >
                 <StyledTextField
                  data-testid="bidAmount"
                  label={`Quote Amount* ${isFixedPrice?'':'(per hour)'}`}
                  placeholder={configJSON.amountPlaceholderTxt}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                  name="amount"
                  value={inputs.amount}
                  onChange={this.handleInputChange}
                  error={Boolean(errors.amount)}
                  helperText={errors.amount}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography style={webStyle.dollarSignStyle}>{loggedInUserCurrency}</Typography>
                      </InputAdornment>
                    ),
                    style: {
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: 600,
                      color: '#000',
                    },
                  }}
                />
              </Grid>
              }
              <Grid item xs={12} md={isNonBidProject?6:4}>
                <StyledTextField
                  data-testid="startDate"
                  label="Start Date*"
                  placeholder="Select Start Date"
                  inputProps={{
                    min:this.getCurrentDate(),
                    style: {
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: 600,
                      color: '#000',
                    }
                  }}
                  fullWidth
                  type={"date"}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="startDate"
                  value={inputs.startDate}
                  onChange={this.handleInputChange}
                  error={Boolean(errors.startDate)}
                  helperText={errors.startDate}
                />
              </Grid>
              <Grid item xs={12} md={isNonBidProject?6:4}>
                <StyledTextField
                  data-testid="endDate"
                  label="End Date*"
                  placeholder="Select End Date"
                  fullWidth
                  type={"date"}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min:this.getCurrentDate(),
                    style: {
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: 600,
                      color: '#000',
                    }
                  }}
                  name="endDate"
                  value={inputs.endDate}
                  onChange={this.handleInputChange}
                  error={Boolean(errors.endDate)}
                  helperText={errors.endDate}
                />
              </Grid>
            </Grid>
            <StyledTextField
              data-testid="proposal"
              label="Proposal*"
              placeholder={configJSON.proposalPlaceholderTxt}
              fullWidth
              inputProps={{
                style: { height: "150px",
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 600,
                color: '#000',    
              },
              }}
              multiline={true}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              name="description"
              value={inputs.description}
              onChange={this.handleInputChange}
              error={Boolean(errors.description)}
              helperText={errors.description}
            />
            <br />
            <br />
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button data-testid="backBtn2" onClick={this.goBack} style={webStyle.backButton} variant="contained">
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button data-testid="continueBtn" onClick={this.handleSubmit} style={webStyle.continueButton} variant="contained">
                  {
                    submitBidLoading?<CircularProgress 
                    style={{padding: "0px 10px 0px 10px"}}
                    size={20}/>:"Continue"
                  }
                  
                </Button>
              </Grid>
            </Grid>
          </Card>
          <br/>
          <br/>
          <br/>
        </Container>
      }
    </ThemeProvider>
    );
  }
}

export default BidProjectDetails;


const webStyle = {
    header: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
      flexWrap: "wrap" as const,
    },
    backButton: {
      boxShadow: "none",
      borderRadius: "20px",
      padding: "10px 30px",
      textTransform: "capitalize",
    } as const,
    continueButton: {
      boxShadow: "none",
      borderRadius: "20px",
      padding: "10px 30px",
      background: "#EC952D",
      color: "white",
      textTransform: "capitalize",
    } as const,
    headingText: {
      fontWeight: "bolder",
      fontSize: "24px",
      color: "black",
    } as const,
    bidCardStyle: {
      border: "1px solid rgb(228, 230, 232)",
      padding: "30px",
      borderRadius: "20px",
    },
    successPageContainer: {
      minHeight: "calc(100vh - 300px)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    } as const,
    subHeading: {
      color: "#8a78a9",
      fontWeight: "bolder",
      fontSize: "17px",
      textAlign: "center"
    } as const,
     dollarSignStyle :{
      fontFamily: 'Inter',
      fontSize: '14px', 
      fontWeight: 600,
      color:'#000'
    },
    projects_filter_wrapper: {
      border: "1px solid #e4e6e8",
      borderRadius: "16px",
      padding: "20px 25px",
      margin: "20px 0"
    },
    milestone_list: {
      backgroundColor: "#f6f6f9",
      padding: "15px",
      marginBottom: "10px",
      borderRadius: "15px"
    },
   
  };

// Customizable Area End
