import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { toast } from "react-toastify"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history?: any;


}
export interface S {
  // Customizable Area Start
  open: boolean;
  notificationdata: any[];
  loading: boolean;
  anchorEl: null | HTMLElement;
  deleteNotificationId: null | number;
  currentPage: number;
  totalNotifications:number;
  currentUser:any;
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class NotificationController extends BlockComponent<
  Props, S, SS
> {

  // Customizable Area Start
  getNotificationCallId: any;
  deleteNotificationAPICallId: string = "";
  clearNotificationsAPICallId: string = "";
  unreadNotificationApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage),
    ];

    this.state = {
      // Customizable Area Start
      open: false,
      notificationdata: [],
      loading: true,
      anchorEl: null,
      deleteNotificationId: null,
      currentPage: 1,
      totalNotifications:0,
      currentUser:{}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentWillMount() {
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
      this.handleUserMessage(user)
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getNotificationCallId) {
        if (this.state.deleteNotificationId) {
          this.setState({ notificationdata: responseJson.data, deleteNotificationId: null, totalNotifications: responseJson.meta.pagination_info.total_data })
        }
        else {
          const newData = responseJson.data;
          const existingData = this.state.notificationdata;
          const mergedData = [...existingData, ...newData];
          const uniqueData = mergedData.filter((item, index, self) =>
            index === self.findIndex((t) => (
              t.id === item.id
            ))
          );
          this.setState({
            notificationdata: uniqueData, loading: false,
            totalNotifications: responseJson.meta.pagination_info.total_data
          },()=>{
            this.unreadNotification(newData)
          })
        }
      }
    
      if (apiRequestCallId === this.deleteNotificationAPICallId) {
        const { deleteNotificationId, notificationdata } = this.state
        const notifications = notificationdata.filter(notification => notification.attributes.id !== deleteNotificationId)
        this.setState({ anchorEl: null, notificationdata: notifications }, () => {
          toast.success("Notification deleted")
          this.getNotification()
        })
      }
      if (apiRequestCallId === this.clearNotificationsAPICallId) {
        this.setState({ notificationdata: [], currentPage: 1,totalNotifications:0 }, () => toast.success("Cleared all notifications."))
      }

      if(apiRequestCallId === this.unreadNotificationApiCallId){
          const msg: Message = new Message(
            getName(MessageEnum.NotificationCount)
          );
          this.send(msg);
      }
    }
    // Customizable Area End
  }
  //web events

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.sendLoginUserMessage();
 }
 sendLoginUserMessage=()=>{
  const currentUserMessage: Message = new Message(
    getName(MessageEnum.GetCurrentUserMessage)
  );
  this.send(currentUserMessage);
}

handleUserMessage = (user:any) =>{
  if(user?.id){
    this.setState({ currentUser: user},()=>this.getNotification());
  }
}

  handleClick = (event: React.MouseEvent<HTMLElement>, notificationId: number) => {
    this.setState({ anchorEl: event.currentTarget, deleteNotificationId: notificationId })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleViewMore = () => {
    this.setState((prev) => ({ currentPage: prev.currentPage + 1 }), () => this.getNotification())
  }

  getNotification = async () => {

    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("loginSuccessToken")
    };

  let IsSponsor = this.state?.currentUser?.currentState?.toLowerCase()?.includes("sponsor") ? "sponsor" : "freelancer" 
  this.state?.currentUser &&   console.log(IsSponsor,"IsSponsor")

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationsAPIEndpoint}=${this.state.currentPage}&role=${IsSponsor}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleDeleteNotification = async () => {

    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
    };

    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.deleteNotificationAPIEndpoint}/${this.state.deleteNotificationId}`,
      body: null,
      method: "DELETE",
    });
    this.deleteNotificationAPICallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);

  }

  handleClearNotifications = async () => {

    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
    };

    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.clearNotificationsAPIEndpoint,
      body: null,
      method: "DELETE",
    });
    this.clearNotificationsAPICallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);

  }

  unreadNotification = async(data : any)=>{
    const unreadNotificationId = data?.filter((data:any)=>!data.attributes.is_read).map((value:any)=>"ids[]=" + value?.id).join('&')
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("loginSuccessToken")
    };
    
     const apiRequestMessage = CreateRestApiMessage({
       header,
       apiUrl: `${configJSON.unreadNotificationApiUrl}?${unreadNotificationId}`,
       body: null,
       method: "PATCH",
     });
     this.unreadNotificationApiCallId = apiRequestMessage.messageId;
     runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
   
  }

  // Customizable Area End
}

