Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.httpPutMethod="PUT";
exports.httpPatchMethod="PATCH";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableuserprofiles2";
exports.labelBodyText = "customisableuserprofiles2 Body";
exports.paswrdErrorMsg = 'Passwords do not match';

exports.btnExampleTitle = "CLICK ME";
exports.skillsTitile = "Skills";
exports.categoryTitle = "Categories";
exports.primarySkills = "Primary Skills*";
exports.labelSkills = "Skills*";
exports.labelCategory = "Categories Interested*";
exports.categoryInterest = "Categories Interested in*";
exports.secondarySkills = "Secondary Skills";
exports.university = "University";
exports.fieldofStudy = "Field of Study";
exports.resumeAndcertfifcates = "Resume & Certifications";
exports.uploadResume = "Upload Documents";
exports.certifications = "Certifications";
exports.freelancingExperience = "Freelancing Experience";
exports.freelancingPlaceHoldertxt ="Enter your experience"
exports.noOfYears = "No. of Years";
exports.workExperience = "Work Experience";
exports.addAnotherExperianceBtn = "+ Add Another Experience";
exports.addAnotherEduBtnTxt = "+ Add Another Education";
exports.jobTitle = "Job Title*";
exports.employerName = "Employer Name*";
exports.country = "Country*";
exports.city = "City*";
exports.jobDescription = "Job Description*";
exports.cancelBtnTxt = "Cancel";
exports.saveBtnTxt = "Save";
exports.submitBtn = "Submit";
exports.education = "Education";
exports.hourlyRateandAvialabiltyTxt = "Hourly Rate";
exports.addNewExperiance = "Add New Work Experiance";
exports.selectYourJobTitle = "Enter your job title";
exports.selectConteryTxt = "Select your country";
exports.selectYourCityTxt = "Select city";
exports.hourlyRate = "Hourly Rate";
exports.emailTxt = "Email";
exports.psrdTxt = "Password";
exports.mobileTxt = "Mobile";
exports.hourlyRateLabel = "Total Availability";
exports.verificationCodeTxt = "Verification Code";
exports.verificationCodePlaceHolder = "Please enter verification code";
exports.newEmailPlaceHolder = "Please enter Email ID";
exports.verifyBtnTxt = "Verify";
exports.resendBtnTxt = "Resend";
exports.currentPassword = "Current Password";
exports.newPsrdwordTxt = "New Password";
exports.confirmPasswordTxt = "Confirm Password";
exports.newEmailAddressTxt = "New Email Address";
exports.forgotPsrdswordTxt = "Forgot Password?";
exports.newPasswordPlaceHolder = "Enter Your Password";
exports.selectYourCityTxt = "Select your city";
exports.addressLine1 = "Address Line 1*";
exports.addressLine2 = "Address Line 2*";
exports.mobileNumber = "Phone No*";
exports.timeZone = "Time Zone*";
exports.DOBTxt = "D.O.B*";
exports.lastNameTxt = "Last Name*";
exports.firstNameTxt = "First Name*";
exports.myProfileTxt = "My Profile";
exports.genderTxt = "Gender*";
exports.uploadTxt = "Upload";
exports.uploadFileBtnTxt = "Upload a file";
exports.showYourPhoto = "Show the best version of yourself to the Sponsors";
exports.showYourFreelancerPhoto = "Show the best version of yourself to the Freelancers";
exports.logoPhoto = "Upload the logo of your company";
exports.mustBeactualPhotoTxt = "Must be an actual photo of you.";
exports.stateTxt = "State*";
exports.cityTxt = "City*";
exports.selectYourGender = "Select your gender";
exports.male = "male";
exports.female = "female";
exports.attachImgTxt = "Attach or Drop photo here";
exports.attachCorporateImgTxt = "Attach or Drop Company logo here"
exports.maxFileSize = "(5 MB Max)";

exports.getProfileDetailsEndPoint = "bx_block_profile/profiles";
exports.getProfileIdEndpoint = "bx_block_profile/profiles/null";
exports.httpPostMethod = "POST";
exports.httpGetMethod = "GET";
exports.httpPutMethod = "PUT";
exports.httpDeleteMethod = "Delete";
exports.emailTxt = "Email ID";
exports.emaiIDplaceHolder = "Enter your email address";
exports.updateBasicDetailsEndPoint = "bx_block_profile/profiles";
exports.mobileNumberPlaceholder = "Enter your Phone No";
exports.postalCode = "Pin Code*";
exports.postalCodePlaceHolder = "Enter pin code";
exports.hourlyDetailsEndPoint = "bx_block_profile/hour_rates";
exports.forgotPasswordEmailplaceHolder = "Enter your email ID";
exports.getCurrentUserInfo = "bx_block_profile/profiles/account_info";
exports.forgotPasswordOtp = "bx_block_forgot_password/otps";
exports.verifyOtpEndPoint =
  "bx_block_forgot_password/otp_confirmations/verify_otp";
exports.ChangePassswordWithCurrentPasswordEndpoint =
  "bx_block_profile/passwords/";
exports.addAnotherExperianceEndpoint = "bx_block_profile/career_experiences";
exports.addAnotherWorkExperianceEndpoint = "bx_block_profile/profiles/add_work_experience";
exports.addAnotherEducationEndpoint = "bx_block_profile/profiles/add_educational_qualification";
exports.getProfileReviewDetailsEndPoint = "bx_block_reviews/reviews/get_review_by_profile_id";
exports.removeSkillEndPoint = "bx_block_profile/skills/remove_skill";
exports.uploadPhotoTxt = "Upload Photo";
exports.universityPlaceHolder = "Please enter your University name";
exports.filedOfStudyPlaceHolder = "Please enter your University name";
exports.mobileNumberPlaceholder = " Enter your phone no.";
exports.sponserHeading = "Profile Setting";
exports.balanceTitle = "Balance";
exports.yourBalanceText = "Your balance is $500.00";
exports.addBalanceButton = "Add Balance";
exports.billingMethodsTxt = "Billing Methods";
exports.addNewBalanceMethod = "Add New";
exports.savedCardTxt = "Saved Cards";
exports.creditCardIconaltTxt = "Credit Card Icon";
exports.creditCardExpireDateTxt = "expires on 9/23";
exports.companyNameLabel = "Company Name";
exports.companyNamePlaceHolderTxt = "Enter your company name";
exports.compoanyWebsitePlaceholder = "Enter your company website";
exports.companyWebsiteTxt = "Company Website";
exports.companyContactTxt = "Company Contact";
exports.companyContactTxtPlaceholder = "Enter your company contact";
exports.companyAddressTxt = "Company Address";
exports.companyAddressPlaceHolderTxt = "Enter your company address";
exports.IndustryLabelTxt = "Company Industry";
exports.companyIndustryPlaceHolderTxt = "Select your industry";
exports.companySizeTxt = "Company Size";
exports.vatIdTxt = "VAT Number"
exports.companySizePlaceHolderTxt = "Enter your company size";
exports.vatNumberTxt = "Enter your VAT number";
exports.cardNumberTxt = "Card Number";
exports.cardNumberPlaceholderTxt = "Enter your card number";
exports.firstNamePlaceholderTxt = "Enter your first name";
exports.lastNamePlaceholderTxt = "Enter your last name";
exports.expiresOnTxt = "Expires On";
exports.cvvTxt = "CVV";
exports.billingAddress = "Billing Address";
exports.addressline1PlaceholderTxt = "Enter your Address Line 1";
exports.addressline2PlaceholderTxt = "Enter your Address Line 2";
exports.postalCodePlaceholderTxt = "Enter your Postal code";
exports.snackBarMessage = "Your Basic details updated successfully";
exports.updateCompanyDetailsEndpoint = "bx_block_companies/companies/";
exports.primarySkillsPlaceHoldertxt = "Search and select your skills"
exports.primaryNoSkillsPlaceHoldertxt = "No skills available"
exports.categoryPlaceHoldertxt = "Search and select your categories"
exports.categoryNoPlaceHoldertxt = "No categories available"
exports.getSkillsEndPoint = 'bx_block_profile/skills/get_skills'
exports.getindustrylist = 'bx_block_profile/industries'
exports.getFieldStudyEndPoint = 'bx_block_profile/profiles/field_of_study_list'
exports.getEducationEndPoint = 'bx_block_profile/profiles/education_list'
exports.getUniversityPoint = 'bx_block_profile/profiles/user_universities'
exports.updateCarrerExperience = 'bx_block_profile/career_experiences'
exports.updateEducationApiCall = '/bx_block_profile/profiles/update_educational_qualification?educational_qualification_id='
exports.deleteCarrerExperience = "bx_block_profile/profiles/remove_work_experience?work_experience_id="
exports.employerNamePlaceholder="Enter your employer name"
exports.jobDecritptionPlaceholder ="Enter your Job Decritption"
exports.addCertificationsPlaceholder ="Type and add your certifications"
exports.changePassswordWithOtpEndpoint = 'bx_block_forgot_password/passwords'
exports.createNewSkillEndpoint = 'bx_block_profile/skills/dashboard_skill'
exports.removeDocumentsEndPoint = 'bx_block_profile/profiles/remove_documents'
exports.paswrdErrorMsg = 'Passwords do not match'

exports.genderSelectTxt= "Select your gender";
exports.countryCodeSelectTxt= "Select";
exports.countrySelectTxt="Select your country";
exports.citySelectTxt="Select your city";
exports.timezoneSelectTxt="Select your timezone";
exports.stateSelectTxt="Select your state";
exports.avoidNumbersPattern="^\\d*$"
exports.perHourTxt="per hour"
exports.successProfileMsg ="Profile updated successfully!";
exports.successHourlyRatesMsg ="Hourly rates added successfully!";
exports.errorHourlyRatesMsg ="Something went wrong."
exports.perHourDollar='$';
exports.getCountriesEndPoint = 'bx_block_profile/profiles/get_countries'
exports.getStateEndPoint = 'bx_block_profile/profiles/get_states?country_name='
exports.getTimeZonesEndPoint = 'bx_block_profile/profiles/time_zones?country='
exports.pdfLabel ="PDF";
exports.uploadLabel =".Uploaded ";
exports.successfulDeleteMsg ="Deleted Successfully!"
exports.DateValidationErrorMsg ="DOB should not be less than 1900"
exports.getCategoryEndPoint = "bx_block_profile/projects/get_project_categories"
exports.deleteEducationApiUrl= "bx_block_profile/profiles/remove_educational_qualification?educational_qualification_id="
exports.getFreelancerExpApiCallUrl = "bx_block_auctionbidding/freelancer_experiences"
exports.addNewexperience ="Add New Work Experience"
exports.addNewEducation ="Add New Education Detail"
exports.educationTxt ="Education*"
exports.universityTxt ="University/College*"
exports.fieldStudyTxt ="Field of Study*"
exports.educationSelectTxt="Select your degree/diploma";
exports.universitySelectTxt="Select your university/college";
exports.fieldStudySelectTxt="Select your field of study";
exports.companyNameTxt = "Company Name*";
exports.companyNamePlaceholderTxt = "Enter your company name";
exports.yearRegTxt = "Year of Registration*";
exports.yearRegPlaceholderTxt = "Enter year of registration";
exports.yearRegTxt = "Year of Registration*";
exports.yearRegPlaceholderTxt = "Enter year of registration";
exports.companyRegNoTxt = "Company Registration No";
exports.companyRegNoPlaceholderTxt = "Enter registration number";
exports.companyWebsiteTxt = "Company Website";
exports.companyWebsitePlaceholderTxt = "Enter company website";
exports.companyProfileTxt = "Company Profile*";
exports.companyProfilePlaceholderTxt = "Enter your company profile";
exports.awardCertificationTxt = "Awards and Recognitions";
exports.awardCertificationPlaceholderTxt = "Enter any awards and recognitions";
exports.getFreelancerCorporatePoint = "bx_block_companies/companies"
exports.maxCorporateFileSize = "( 5MB Max )"
exports.stateCorporateSelectTxt = "Select your state"
exports.industryCorporateSelectTxt = "Select your industry"
exports.corporateCityLabel = "City*";
exports.cityCorporateSelectTxt = "Select your city"
exports.postalCorporateSelectTxt = "Enter your postal code"
exports.noOfEmpTxt = "Number of Employees*";
exports.empCorporateSelectTxt = "Enter no. of Employees"
exports.corporatePostalLabel = "Postal Code*";
exports.overviewCorporateSelectTxt = "Enter company overview"
exports.overviewTxt = "Company Overview*";
exports.vatTaxCorporateSelectTxt = "Enter your VAT ID"
exports.vatTaxTxt = "VAT ID*";
exports.updateCorporateDetailsEndPoint="bx_block_companies/companies"
exports.getEnabledCountriesApiUrl = 'bx_block_profile/profiles/get_countries?enable=true'
exports.getEnabledStateApiUrl = 'bx_block_profile/profiles/get_states?country_name='
exports.placeholderCity="Enter city"
exports.placeholderState="Enter state"
exports.errorUploadResume="Please upload only PDF, DOC, or DOCX files."
exports.profileReset = "Profile reset successfully"
exports.getHourlyRatesApiCallUrl = "bx_block_profile/hour_rates"
exports.loaderTxt = "loading..."
exports.freelancingExperiencePlaceholder = "Enter Freelancing Experience"
exports.backBtn = "Back"
exports.sponsorDetailsText = "Sponsor Details"
exports.memberSinceText = "Member Since"
exports.totalProjectText = "Total Projects"
exports.totalSpentText = "Total Spent"
exports.companySizeText = "Company Size"
exports.postedOnText = "Posted on"
exports.feedBackByLancer = "Feedback by"
exports.freelancerDetailsText= "Freelancer Details"
exports.textTotalEarning = "Total Earnings"
exports.textTotalExp = "Total Experience"
exports.textTotalHours = "Total Hours"
exports.textLanguage = "Languages"
exports.textVerification = "Verification"
exports.experienceDetailsText = "Experience Details"
exports.educationDetailsText = "Education Details "
exports.linkSendMsg="Please check your inbox and click on the link to reset your password."
exports.noWorkExpFound= "No work experience added"
exports.noEducationFound= "No education details added"
exports.NoData= "No Data Found"
exports.skillsLabel= "Skills"
exports.localTimeLabel= "local time"
exports.pastProjectsLabel= "Past Projects & Reviews"
exports.categoryLabel= "Categories"
exports.menuDefaultTxt="choose"
exports.getCurrencyApiCallUrl="bx_block_profile/profiles/get_currency_listing?enable=true"

